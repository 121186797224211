<template>
  <v-container fluid>

    <card :title="$t('models.certification_authority.selves')">
      <template v-slot:actions>
        <v-btn small color="success" @click="createModel()">
          <v-icon left small>fa fa-plus</v-icon>
          {{ $t('actions.create') }}
        </v-btn>
      </template>
      <!-- table -->
      <grid-view ref="grid" :headers="headers" endpoint="certification-authority" model="certification_authority">
        <template v-slot:item._actions="{ item }">
          <v-btn @click="editModel(item)" icon small>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteModel(item)" icon small>
            <v-icon x-small>fa fa-trash</v-icon>
          </v-btn>
        </template>
      </grid-view>
    </card>

    <!-- view dialog -->
    <v-dialog v-model="viewDialog" max-width="600">
      <v-card v-if="model">
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">
              {{ model.name }} </h3>
            <div>{{ model }}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn text @click="editModel(model)">{{ $t('actions.edit') }}</v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="viewDialog = false">{{ $t('actions.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="600">
      <Form :modelId="editedModelId" v-if="editDialog" @save="onDialogSave"/>
    </v-dialog>

  </v-container>
</template>

<script>
import {Vue, Component} from "vue-property-decorator";
import Form from "@/pages/certification-authority/Form";
import formatter from '@/mixins/formatter';
import Card from "@/components/Card";
import GridView from "@/components/GridView";
import {setPageTitle} from "@/utils/meta";


@Component({components: {GridView, Form, Card}, mixins: [formatter]})
export default class CertificationAuthorityIndex extends Vue {
  viewDialog = false;
  editDialog = false;
  model = null;
  editedModelId = null;

  created() {
    setPageTitle(this.$i18n.t('models.certification_authority.selves'));
  }

  get headers() {
    return [
      {text: '', value: '_actions',},
      {text: this.$i18n.t('models.certification_authority.name'), value: 'name', sort: this.i18nSort},
    ];
  }

  createModel() {
    this.editedModelId = null;
    this.model = {
      name: "",
    }
    this.editDialog = true;
  }

  editModel(model) {
    this.viewDialog = false;
    this.editedModelId = model.id;
    this.editDialog = true;
  }

  deleteModel(model) {
    this.$root.$confirm.open('Delete certification authority', 'Are you sure you want to delete this certification authority ?', {}).then((confirm) => {
      if (confirm) {
        this.$api.delete(`certification-authorities/${model.id}`).then(() => {
          this.$refs.grid.getModels();
        }).catch(() => {
          alert("Unable to remove certification authority");
        });
      }
    });
  }

  onDialogSave(model) {
    this.$refs.grid.getModels(model.id);
    this.editDialog = false;
    this.editedModelId = null;
  }

}
</script>
